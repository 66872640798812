import { useSelector } from 'react-redux';
import { ILocation } from '../../../entities/general.entities';
import { selectFarmsData } from '../../../store/farms/farms.selector';
import { selectUserMeta } from '../../../store/auth/auth.selector';
import { useMemo, useState } from 'react';
import { getLineStatus } from '../../../lib/farm.helpers';
import { LINE_COLORS } from '../../../lib/constant';
import { Modal } from 'antd';
import CloseIcon from '../CloseIcon';
import Subtitle from '../subtitle/Subtitle';
import { Map, Button } from '../';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';

const defaultCenter = {
  lat: -41.0917585,
  lng: 173.828391,
};

interface Props {
  visible: boolean;
  onConfirm: (d: ILocation[]) => void;
  onCancel: () => void;
  points?: ILocation[];
  farmID: number;
}

const LineLocationModal = ({
  visible,
  onConfirm,
  onCancel,
  points,
  farmID,
}: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);
  const userMeta = useSelector(selectUserMeta);
  const { polygons, lines, center } = useMemo(() => {
    const polygons = farmsData
      .filter(x => x.bounds && x.bounds.length > 2)
      .map(x => x.bounds as ILocation[]);

    let lines = [];
    for (let farm of farmsData) {
      for (let line of farm.lines) {
        if (!line.points || line.points.length < 2) continue;
        const color = getLineStatus(line, userMeta?.line_assess_expire_days);
        lines.push({
          points: line.points,
          color: LINE_COLORS[color],
        });
      }
    }
    const farm = farmsData.find(x => x.id === farmID);
    const center =
      farm && farm.bounds && farm.bounds.length > 0
        ? farm.bounds[0]
        : defaultCenter;

    return { polygons, lines, center };
  }, [farmsData, farmID]);

  const [firstLoc, setFirstLoc] = useState<ILocation | null>(
    points && points.length > 0 ? points[0] : null,
  );
  const [secondLoc, setSecondLoc] = useState<ILocation | null>(
    points && points.length > 1 ? points[1] : null,
  );

  const updateLocation = (location: ILocation) => {
    if (!firstLoc) {
      setFirstLoc(location);
    } else if (!secondLoc) {
      setSecondLoc(location);
    } else {
      setFirstLoc(location);
      setSecondLoc(null);
    }
  };
  const confirmClick = () => {
    if (!firstLoc || !secondLoc) {
      window.alert(translate(lang, 'Please select two points'));
      return;
    }
    onConfirm([firstLoc, secondLoc]);
  };

  const allLines =
    firstLoc && secondLoc
      ? [...lines, { points: [firstLoc, secondLoc], color: 'red' }]
      : [...lines];

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Pickup location')}
          </Subtitle>
        </div>
        <div className='mb-16 w-100 d-flex justify-content-between'></div>
        <div className='mb-16'>
          <div style={{ height: '450px' }}>
            <Map
              zoom={17}
              defaultPosition={points && points.length > 0 ? points[0] : center}
              onCallback={pt => updateLocation(pt)}
              polygons={polygons}
              lines={allLines}
              isStayLocation={true}
              hideMarker={true}
            />
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <Button
            className='mr-17'
            width={'small'}
            size={2}
            type='fill'
            color='blue'
            onClick={onCancel}
          >
            {translate(lang, 'Cancel')}
          </Button>
          <Button
            className='ml-17'
            width={'small'}
            size={2}
            type='fill'
            color='green'
            onClick={confirmClick}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LineLocationModal;
